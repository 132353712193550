:root {
  --background-color: #f1f9ff;
  --border-color: #707070;
  --white: #ffffff;
  --black: #000000;
  --text-color: #292929;
  --green: #0c9a00;
  --yellow: #feb600;
  --primary-color: #2475ba;
  --button-red-color: #e7627a;
  --button-disabled-color: #91929f;
  --subchapter-read-color: #c3d62d;
  --subchapter-unread-color: #b6c0c5;
  --shadow-color: #00000029;
  --ul-marker-color: #67a04a;
  --header-height: 56px;
  --spacing: 8px;
  --page-padding: 8px;
  --card-padding: 8px;
  --navigation-button-size: 56px;
  --font-size-s: 14px;
  --line-height-s: 21px;
  --font-size-m: 16px;
  --line-height-m: 21px;
  --font-size-l: 20px;
  --line-height-l: 24px;
  --font-size-xl: 28px;
  --line-height-xl: 37px;
  --border-radius-small: 4px;
  --border-radius-medium: 8px;
  --border-radius-large: 10px;
  --border: 1px solid var(--border-color);
  --desktop-size: 1000px;
}

html {
  height: 100%;
}

#root {
  height: 100%;
}

body {
  height: 100%;
  margin: 0;
  background: var(--background-color);
  color: var(--text-color);
  font-size: var(--font-size-m);
  line-height: var(--line-height-m);
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
}
