.card {
  display: flex;
  cursor: pointer;
}

.expand {
  padding-right: var(--spacing);
  height: var(--line-height-m);
}

.question {
  font-weight: bold;
}

.answer {
  font-size: var(--font-size-s);
  line-height: var(--line-height-s);
}

.answerParagraph:last-child {
  margin-bottom: 0;
}

.answerList {
  padding-inline-start: 20px;
}

.answerList:only-child {
  margin-bottom: 0;
}
