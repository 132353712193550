.button {
  border: var(--border);
  cursor: pointer;
  font-size: var(--font-size-m);
  line-height: var(--line-height-m);
}

.red {
  background: var(--button-red-color);
  box-shadow: 0 3px 3px var(--shadow-color);
  border-radius: var(--border-radius-large);
  width: var(--navigation-button-size);
  height: var(--navigation-button-size);
  color: var(--white);
}

.blue {
  background: var(--primary-color);
  box-shadow: 0 1px 2px var(--shadow-color);
  border-radius: var(--border-radius-medium);
  color: var(--white);
}

.white {
  background: var(--white);
  box-shadow: 0 1px 1px var(--shadow-color);
  border-radius: var(--border-radius-small);
  color: var(--primary-color);
}

.transparent {
  background: var(--white);
  border: none;
}

.button.disabled {
  background: var(--button-disabled-color);
}
