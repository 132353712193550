:root {
  --check-size: 106px;
}

.checkContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.check {
  color: var(--green);
  font-size: var(--check-size);
  line-height: 0;
}

.checkText {
  font-size: var(--font-size-l);
  line-height: var(--line-height-l);
  font-weight: bold;
}
