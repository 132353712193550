.headerContainerOuter {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.headerContainer {
  display: flex;
  align-items: center;
}

.backButton {
  margin-right: var(--spacing);
}
