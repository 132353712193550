.modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.modalContent {
  padding: 0 !important;
  height: 100%;
}
