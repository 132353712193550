.page {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.spacingContainer {
  flex: 1;
  position: relative;
}

.buttonContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  width: 100%;
  bottom: var(--spacing);
  margin-bottom: calc(-1 * var(--spacing));
}

.progressContainer {
  position: absolute;
  bottom: calc(-1 * (var(--navigation-button-size) + var(--line-height-m)) / 2);
  width: 100%;
}

.progressBarContainer {
  position: relative;
  top: var(--page-padding);
  margin: 0 calc(-1 * var(--page-padding));
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
}
