.container {
  font-size: var(--font-size-s);
  line-height: var(--line-height-s);
}

.word {
  font-size: var(--font-size-s);
  line-height: var(--line-height-s);
  margin: 1em 0;
}

.definition {
  font-size: var(--font-size-s);
  line-height: var(--line-height-s);
}
