.markdown > h1 {
  color: var(--primary-color);
  margin: 1em 0;
  font-size: var(--font-size-m);
  line-height: var(--line-height-m);
}

.markdown a {
  text-decoration: underline;
}

.markdown ul {
  padding-inline-start: 30px;
}

.markdown p ~ ul {
  top: -1rem;
  position: relative;
  margin-top: 0;
  margin-bottom: -1rem;
}
