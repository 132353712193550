:root {
  --speaker-size: 24px;
}

.container {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: var(--spacing);
}

.speaker {
  width: var(--speaker-size);
  height: var(--speaker-size);
  margin-right: var(--spacing);
  color: var(--primary-color);
}

.text {
  font-weight: bold;
  color: var(--primary-color);
}
