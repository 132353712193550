.textContainer {
  padding: var(--spacing);
  display: flex;
  align-items: center;
  color: var(--text-color);
  font-weight: bold;
}

.number {
  padding-right: calc(2 * var(--spacing));
}
