.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.pageContainer {
  padding: 0 calc((100vw - var(--desktop-size)) / 2);
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.page {
  padding: var(--spacing);
  flex: 1;
}

@media (min-width: 1000px) {
  /* var(--desktop-size) */
  .page {
    width: calc(var(--desktop-size) - 2 * var(--spacing));
    margin: auto;
  }
}

.backgroundWhite {
  background: var(--white);
}

.backgroundBlack {
  background: var(--black);
  color: var(--white);
}
