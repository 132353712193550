.page {
  padding: 0;
  height: 100%;
}

.pageContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.contentContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
  color: var(--white);
  font-size: 42px;
  padding: var(--spacing) var(--spacing) 0;
}

.closeButton {
  cursor: pointer;
}

.imageContainer {
  overflow: hidden;
  display: flex;
  justify-content: center;
}

.image {
  width: 100%;
  height: 100%;
}

.caption {
  text-align: center;
  padding: var(--spacing);
}
