:root {
  --progress-bar-height: 8px;
}

.outerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.innerContainer {
  display: flex;
}

.title {
  padding: var(--spacing);
  display: flex;
  align-items: center;
  color: var(--text-color);
}

.progress {
  border-bottom: var(--progress-bar-height) solid var(--subchapter-unread-color);
  border-radius: var(--border-radius-small);
  position: relative;
  top: var(--card-padding);
  margin: 0 calc(-1 * var(--card-padding)) 0;
}

.progress.read {
  border-bottom: var(--progress-bar-height) solid var(--subchapter-read-color);
}
