.progressContainer {
  display: flex;
  justify-content: center;
}

.progressText {
  word-break: break-word;
  width: calc(
    100vw - 2 *
      (var(--navigation-button-size) + var(--page-padding) + var(--spacing))
  );
  display: flex;
  justify-content: center;
}
