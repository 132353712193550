:root {
  --gallery-button-size: 16px;
}

.container1 {
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 100%;
}

.container2 {
  display: flex;
  position: relative;
  left: 50%;
  transition: left 0.7s;
  will-change: transform;
}

.container3 {
  position: relative;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.imageContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.caption {
  text-align: center;
  font-size: var(--font-size-s);
  margin: 0 calc(var(--spacing) / 2);
}

.clickable {
  cursor: pointer;
}

.zoomable {
  cursor: zoom-in;
}

.buttonContainer {
  display: flex;
  justify-content: center;
  margin-top: var(--spacing);
}

.button {
  border-radius: 50%;
  width: var(--gallery-button-size);
  height: var(--gallery-button-size);
  background: var(--white);
  border: var(--border);
  cursor: pointer;
}

.button:not(:last-child) {
  margin-right: var(--spacing);
}

.button.active {
  background: var(--primary-color);
}
