.page {
  display: flex;
  justify-content: center;
}

.container {
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}

.buttonContainer {
  display: flex;
  margin-top: var(--spacing);
}

.clearContainer {
  margin-left: calc(2 * var(--spacing));
  flex: 1;
}

.clear {
  width: 100%;
}

.checkContainer {
  margin: calc(8 * var(--spacing)) 0;
}

.textContainer {
  padding: var(--spacing) calc(2 * var(--spacing));
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.description {
  padding: calc(2 * var(--spacing));
}
